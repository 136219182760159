

































import Component from "vue-class-component";
import { ModelListSelect } from "vue-search-select";
import Vue from "vue";
import { namespace } from "vuex-class";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import { TicketTemplate } from "@/models/ticket-template.model";
import { User } from "@/models/user.model";

const usersNamespace = namespace("Users");
const ticketTemplatesNamespace = namespace("TicketTemplates");

@Component({ components: { ModelListSelect } })
export default class GenerateTicket extends Vue {
  selectedUser: any = {};
  selectedTicketTemplate = null;
  sendToAll = false;
  isLoading = false;

  @ticketTemplatesNamespace.Getter("all")
  ticketTemplates!: PaginatedResult<TicketTemplate>;

  @usersNamespace.Getter("all")
  users!: PaginatedResult<User>;

  @usersNamespace.Action("fetchAll")
  fetchUsers!: () => Promise<PaginatedResult<User>>;

  @ticketTemplatesNamespace.Action("fetchAll")
  fetchTicketTemplates!: () => Promise<PaginatedResult<TicketTemplate>>;

  @ticketTemplatesNamespace.Action("generateFromTicketTemplate")
  generateFromTicketTemplate!: (
    payload: any
  ) => Promise<PaginatedResult<TicketTemplate>>;

  get usersData() {
    return this.users?.data;
  }

  get ticketTemplatesData() {
    return this.ticketTemplates?.data;
  }

  async generateTicket() {
    this.isLoading = true;
    let payload = {};
    if (this.sendToAll) {
      payload = {
        user_ids: this.usersData.map((user) => user.id),
        template_id: this.selectedTicketTemplate,
        send_to_all: true,
      };
    } else {
      payload = {
        user_ids: [this.selectedUser.id],
        template_id: this.selectedTicketTemplate,
        send_to_all: false,
      };
    }
    await this.generateFromTicketTemplate(payload);
    this.isLoading = false;
  }

  async created() {
    this.isLoading = true;
    await this.fetchUsers();
    await this.fetchTicketTemplates();
    this.isLoading = false;
  }
}
